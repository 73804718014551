import React from 'react'



const BlockPlatform =()=> {

    return (
      <section className="secureof lucrative centeralign pt-100 mb-0">
        <div className="container">
        <h2 className="heading-h2 text-center"><span className="bluecolor heading-h3">Blockchain Platform</span> Coinsclone supports for <span className="transNone">dApp</span> Development Services</h2>
        <p className='text-center'>Explore our dApp development services that are correctly aligned with pioneer blockchain platforms. Coinsclone provides dApp development services with well-known blockchains such as,</p>
        <div className="factor">
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/ethereum.png" alt="Ethereum" />
              <span>Ethereum</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/tron-token.png" alt="Tron" /><span>Tron</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/dapp/hyperledger.png" alt="hyperledger" /><span>Hyperledger</span></p>
            </div>
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/binance-smart-chain.png" alt="Binance Smart Chain" /><span>Binance Smart Chain</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/polygon.png" alt="Polygon" /><span>Polygon</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/dapp/polkadot.png" alt="polkadot" /><span>Polkadot</span></p>
            </div>
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/solana.png" alt="Solana" /><span>Solana</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/dapp/avalanche.png" alt="avalanche" /><span>Avalanche</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/dapp/tezos.png" alt="tezos" /><span>Tezos</span></p>
            </div>
          </div>
        </div>
      </section>
    )
}

export default BlockPlatform