import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-0 mb-md-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'><span className="transNone">DeFi dApp</span></span> Development Company</h1>
                <p className="pharagraph mb-0">Get Adaptable and Highly Innovative <span className="transNone">dApp</span> Development Services with Coinsclone for Success Anywhere on the Globe. Hire us today to initiate your <span className="transNone">dApp</span> development ideas. We as a <span className="transNone">dApp</span> development service provider, structure your <span className="transNone">dApp</span> with premium features and functionalities. Our developer team will excel in your <span className="transNone">dApp</span> services.</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Grab a free demo</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/dapp/dapp-development-company.webp"
                alt="dapp development company"
                className='d-none d-md-block float-end'
                width={686}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection