import React from 'react'



class UseCase extends React.Component {

  render() {

    return (
      <section className="icon whitelabel-use dapps pt-100 secureof">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Use Cases</span> Of Our <span className="transNone">dApp</span> Development</h2>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                FinTech and DeFi
                  </h3>
                <p className="pharagraph">We provide you with diverse <span className="transNone">dApp</span> developments in NFT, Cryptocurrency exchange, STO app development, and P2P lending. In addition, we work up <span className="transNone">dApp</span> with streamlined Liquidity Mining, Yield Farming, Data Tracking, Digital Identity, and Cross-chain Exchange. We frame your <span className="transNone">dApp</span> with strong KYC/AML compliance.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Logistics
                </h3>
                <p className="pharagraph">Explore Enhanced Traceability and Authenticity, Streamlined Procurement and Supplier Management, Real-time Visibility, and more with our <span className="transNone">dApp</span> development. By integrating with IoT devices and sensors, our <span className="transNone">dApp</span> development services can capture and record data on product shipments, storage conditions, demand forecasting, and restocking. We ensure your convenience with every feature.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Gaming
                </h3>
                <p className="pharagraph">We guarantee the authenticity and ownership of your assets. Customize your engagement patterns and UX with us. Incorporating player-driven economics, immutable gaming logic, cross-game interoperability, community governance, and play-to-earn mechanisms are all enriched with your <span className="transNone">dApp</span>.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Supply chain
                </h3>
                <p className="pharagraph">We are here to eliminate your paperwork. Our <span className="transNone">dApp</span> development holds the potential to transform your supply chain management by enhancing transparency, traceability, efficiency, and trust across the entire supply chain ecosystem. Hence, we give you the entire control of the goods and chain of custody.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Healthcare and pharma
                </h3>
                <p className="pharagraph">Our <span className="transNone">dApp</span> development has the potential to transform your healthcare and pharma industries by enhancing secure and Interoperable Electronic Health Records (EHRs), Counterfeit Detection, Patient Consent Management, and Patient-Centric care delivery. Our <span className="transNone">dApp</span> development streamlines your drug traceability, payments, and insurance claims which can enhance patient privacy standards.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Real estate
                </h3>
                <p className="pharagraph">Our <span className="transNone">dApp</span> holds the potential to change the real estate industry by introducing innovative solutions to experience property tokenization and transparent property transactions. The incorporated smart contract for Escrow and settlement will ensure that funds are released when predefined conditions are met. With our <span className="transNone">dApp</span> development services, full-on support over immutable property records, real-time property valuation, crowdfunding, and REITs facilitation is guaranteed.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Retail and eCommerce
                </h3>
                <p className="pharagraph mb-0">Our decentralized app development will perfectly fit all your retail and e-commerce business. Being an innovative dapp development company, we foster a more open and competitive environment for retailers with enhanced customer experience. From indulging product sourcing, Implementing transparency in payments, DEX customer loyalty program, Immutable Product Reviews and Ratings, Inventory Management, and Order Fulfillment, we got you covered with every service.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Social media
                </h3>
                <p className="pharagraph mb-0">Data Ownership, Enhanced Privacy, and Security, Monetization of User Content, Incentivized Content Creation, Community Governance and Moderation, Interoperability, and Data Portability are key advantages offered by our <span className="transNone">dApp</span> development services. Your users can experience a more vibrant and engaging social media ecosystem. Our <span className="transNone">dApp</span> provides transparent metrics and analytics to users, enabling them to track their content's performance.</p>
              </div>
              </div>
          </div>
        </div>
      </section>
    )
  }
}

export default UseCase