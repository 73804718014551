import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/dapp/Banner';
import Whatis from '../components/dapp/Whatis';
import CoreFeatures from '../components/dapp/CoreFeatures';
import WhyChoose from '../components/dapp/WhyChoose';
import FaqSection from '../components/dapp/FaqSection';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import Benificial from '../components/dapp/Benificial';
import UseCase from '../components/dapp/UseCase';
import PrimierOffer from '../components/dapp/PrimierOffer';
import TechStack from '../components/dapp/TechStack';
import BlockPlatform from '../components/dapp/BlockPlatform';


const DappDev = () => (
  <section className="cryptoex dApp">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/dapp-development-company/" />
        <title>dApp Development Company| dApp Development Services</title>
        <meta name="description" content="Coinsclone as a dApp Development Company provides Various dApp Development Services For Startups to Create Decentralized Apps.Partner with us for top dApp solutions." />
        <meta name="keywords" content="dapp development company" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="dApp Development Company| dApp Development Services" />
        <meta property="og:description" content="Coinsclone as a dApp Development Company provides Various dApp Development Services For Startups to Create Decentralized Apps.Partner with us for top dApp solutions." />
        <meta property="og:url" content="https://www.coinsclone.com/dapp-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/dapp/dapp-development-company.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone as a dApp Development Company provides Various dApp Development Services For Startups to Create Decentralized Apps.Partner with us for top dApp solutions." />
        <meta name="twitter:title" content="dApp Development Company| dApp Development Services" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/dapp/dapp-development-company.webp" />
      </Helmet>
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | dApp Development Company</span>
        </div>
      </div>
      <Whatis />
      <PrimierOffer />
      <Benificial />
      <UseCase />
      <CoreFeatures />
      <BlockPlatform />
      <WhyChoose />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default DappDev
